/* eslint-disable*/
import VueStoreService from './VueStoreService';
const CategoryTemplateService = {
    async getCategorys(filter,store){
        var resp = await VueStoreService.get("template_category"+filter, store)
        return resp;
    },
    async saveCategory(data,store){
        var resp = await VueStoreService.post("template_category",data,store)
        return resp;
    },
    async getCategoryById(category_id,store){
        var resp = await VueStoreService.get( "template_category/"+category_id,store)
        return resp;
    },
    async updateCategory(category_id,data,store){
        var resp = await VueStoreService.put("template_category/"+category_id,data,store)
        return resp;
    },

    async deleteCategory(category_id,store){
        var resp = await VueStoreService.delete("template_category/"+category_id,store)
        return resp;
    },
}

export default CategoryTemplateService;