/* eslint-disable*/
import VueStoreService from './VueStoreService';
const SubCategoryTemplateService = {
    async getSubCategorys(filter,store){
        var resp = await VueStoreService.get("template_sub-category"+filter, store)
        return resp;
    },
    async saveSubCategory(data,store){
        var resp = await VueStoreService.post("template_sub-category",data,store)
        return resp;
    },
    async getSubCategoryById(subcategory_id,store){
        var resp = await VueStoreService.get( "template_sub-category/"+subcategory_id,store)
        return resp;
    },
    async updateSubCategory(subcategory_id,data,store){
        var resp = await VueStoreService.put("template_sub-category/"+subcategory_id,data,store)
        return resp;
    },

    async deleteSubCategory(subcategory_id,store){
        var resp = await VueStoreService.delete("template_sub-category/"+subcategory_id,store)
        return resp;
    },
}

export default SubCategoryTemplateService;