<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="subcategoriaAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="px-2 py-2 pb-0">
        <b-row class="w-100">
          <b-col lg="4" class="mb-lg-0 mb-1">
            <b-form-group label="Categoria" label-for="category">
              <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="records" label="code"
                input-id="category" :reduce="(records) => records.id" placeholder="Categoria" v-model="categoria"
                @input="filter">
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="2" class="mb-lg-0 mb-2 d-flex justify-content-center justify-content-lg-between align-items-center">
            <div class="d-flex">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="secondary" class="mr-1"
                @click.prevent="clean">
                <span class="align-middle">Ver Todos</span>
              </b-button>
            </div>
            <b-button variant="primary" class="" @click="addSubCate">
              <span class="text-nowrap"><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
import CategoryTemplateService from '@/services/CategoryTemplateService'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      isAdd: false,
      isUpdate: false,
      see_more: false,
      categoria: '',
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      records: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addSubCate() {
      this.isAdd = true
      this.$refs.subcategoriaAdd.setData({})
    },
    async getCategory() {
      this.categoria = ''
      const url =
        `?limit=10000`
      const resp = await CategoryTemplateService.getCategorys(url, this.$store)
      console.log('respcategory', resp)
      if(resp.status){
        this.records = resp.data.rows
      }
      //this.filter()
    },
    async filter() {
     
      var arrayFilter = []
      if (this.categoria != '' && this.categoria != null) {
        arrayFilter.push({
          keyContains: 'category_id',
          key: 'equals',
          value: this.categoria
        })
      }
      arrayFilter.push({
        keyContains: 'category.deleted_at',
        key: 'equals',
        value: null
      })
    
      if (arrayFilter.length > 0) {
        this.$parent.$parent.arrayFilters = arrayFilter
        this.$parent.$parent.getData()
      }

      console.log('arrayFilter', arrayFilter)
    },
    clean() {
     
      this.categoria = null
    
      var arrayFilter = []  
     
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.body_card {
  display: block;
  transition: 0.3s;
}
</style>
